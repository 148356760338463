export const getFavIconStatus = data => {
  const {
    isSaveProductToList = false,
    productSavedToList = false,
    inSavedItems = false,
    isFavorite = false,
    filledWishIcon = {},
    wishIcon = {},
  } = data || {}
  if (isSaveProductToList) {
    return productSavedToList || inSavedItems
      ? filledWishIcon.src
      : wishIcon.src
  } else {
    return isFavorite ? filledWishIcon.src : wishIcon.src
  }
}
